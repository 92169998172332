export default {
	'translation': {
		'common': {
			'backhome': 'Back to main page',
			'free': 'Free',
			'checkout': 'Place order',
			'yes': 'Yes',
			'no': 'No',
			'remove': 'Remove',
			'nodepartment': 'Without department',
			'back': 'Back'
		},
		'index': {
			'kvanbanner': {
				'title': 'Kwan, a ski resort',
				'skipass': 'Ski passes',
				'tubing': 'Tubing',
				'learn': 'Skiing training',
				'rent': 'Rental'
			},
			'oos': {
				'notworking': 'At the moment, the object is not working.',
				'schedule_from': 'The opening hours of the object from {{from}}',
				'schedule': 'The opening hours of the object from {{from}} to {{to}}'
			}
		},
		'itempage': {
			'addtocart': 'add to cart',
			'details': 'Details',
			'scheduleservice': 'Select',
			'scheduleservice_cancel': 'Cancel',
			'timebooked': 'booked',
			'incart': 'in cart',
			'selecttime': 'Choose time',
			'timebooked_close': 'Booking for the current day has already closed'
		},
		'errorpage': {
			'error': 'Error',
			'pagenotfound': 'Page not found :(',
			'attention': 'Attention!',
			'paymentfailed': 'Payment failed!',
			'paymentretry': 'Try again',
			'backtoorder': 'Back to the order'
		},
		'checkout': {
			'yourorder': 'Order',
			'vat': 'VAT',
			'servicefee': 'Service fee',
			'servicefeeinfo': 'To maintain the operation of the service, a service fee is charged in the amount of <b>{{amount}}</b>. The fee covers the costs of order processing, customer support, performance improvements and delivery of goods or services to the buyer.',
			'total': 'Total amount',
			'alcowarn': 'Payment for alcohol is made only upon receipt of the order, via the terminal or in cash. <br/>Delivery - within the territory of the facility',
			'form': {
				'howtopay': 'How is it convenient for you to pay?',
				'room': 'Room',
				'name': 'Name',
				'phone': 'Phone number',
				'phoneplaceholder': 'Enter the phone number',
				'note': 'Wishes',
				'placeorder': 'Place order',
				'phonelengtherror': 'Fill in the phone number',
				'pickup': 'Pickup'
			},
			'item': {
				'timeslot': 'from {{from}} to {{to}}'
			},
			'privacypolicy': {
				'agree': 'You agree',
				'withpolicy': 'with the privacy policy and the offer'
			},
			'payment': {
				'online': 'Online, by card',
				'cash': 'By cash',
				'card': 'Terminal, by card'
			},
			'paymenttype': 'Payment',
			'noorders': 'There are no orders yet',
			'pagetitle': 'Placing an order',
			'options': 'Options',
			'confirmdelete': 'Are you sure you want to delete the entire order?'
		},
		'checkorder': {
			'confirmcancel': 'Are you sure you want to cancel the order?',
			'order': 'Order',
			'payment': 'Payment',
			'paid': 'Paid',
			'notpaid': 'Not paid',
			'changepayment': 'It is possible to change the payment method',
			'paymenttype': 'Payment method',
			'timelefttopay': 'Time left to pay',
			'pay': 'Pay',
			'cancel': 'Cancel'
		},
		'ordersuccess': {
			'orderaccepted': 'Your order has been accepted',
			'orderno': 'Order number # {{ orderID }}',
			'trackorder': 'Track order',
			'receiptlink': 'Link to your FDO payment receipt',
			'pagetitle': 'Order #{{ orderID }}'
		},
		'bannercarousel': {
			'demohotel': {
				'freeday': 'A day of stay as a gift',
				'romantik': 'Romantic dinner'
			}
		},
		'footer': {
			'copyright': 'STAYDAY ©️{{ year }} - Online Storefront Service',
			'privacypolicy': 'Privacy Policy and Offer',
			'notadvert': 'All materials on the site are informational in nature and are not advertising'
		},
		'cookiesmsg': {
			'datausage': 'Data usage',
			'disclaimer': 'Uses cookies and technical data collection services to ensure operability and improve the quality of service.',
			'agree': 'I agree'
		},
		'foradult': {
			'is18': 'Are you already 18 years old?',
			'disclaimer': 'This section contains information that is not recommended for persons under the age of majority.'
		},
		'orderstatus': {
			'statusword': 'Status',
			'status': {
				'new': 'New',
				'split': 'Split',
				'inwork': 'In work',
				'processing': 'Processing',
				'ready': 'Ready to issue',
				'delivered': 'Delivery',
				'completed': 'Delivered',
				'canceled': 'Cancelled',
				'confirmed': 'Confirmed'
			},
			'servicebytime': {
				'status': {
					'new': 'New',
					'processing': 'Processing',
					'confirmed': 'Confirmed',
					'inwork': 'In work',
					'completed': 'Completed',
					'canceled': 'Cancelled'
				}
			}
		},
		'textpage': {
			'abouthotel': 'About hotel',
			'texts': {
				'22': `
          <p>
            SK Royal Hotel Kaluga (ex. Kwan) is a country hotel is a 10-minute drive from the center of Kaluga and two hours from Moscow.
          </p>

          <p>
            The building itself is a unique architectural structure, since in winter the main descent of the «Kvan» ski complex begins on the roof. There are picturesque views of the Oka Delta all around.амо здание является уникальным архитектурным сооружением, так как в зимнее время года на крыше начинается
          </p>

          <p>SK Royal Kaluga Hotel (ex. Kwan) offers its guests a full range of services for a comfortable stay and successful business:</p>

          <p>74 rooms in the main building, 7 bungalows and 2 guest houses; free Wi-Fi throughout; several
            banquet facilities for up to 300 guests, a lobby bar, a winter cafe and a summer veranda; conference rooms,
            equipped with modern equipment; guarded parking for 300 places.</p>

          <p>Two bath houses with Russian baths on a wood-burning stove with a capacity of up to 8 guests, comfortably located in the forest
            the array. There is also a spa center with a gym, sauna, Turkish bath and swimming pool.</p>
        `,
				'68': `
          <p>DemoHotel is a famous hotel located in the heart of the city. This is an ideal solution for recreation of any format: the main attractions, entertainment and business centers are located within walking distance, as well as stunning places for walking</p>

          <p>Our hotel offers excellent accommodation and a wide selection of rooms of various categories, decorated
            in a modern style with exquisite decor and top-quality amenities. Each room has an option
            24-hour service to ensure the comfort and satisfaction of our guests.</p>

          <p>We are proud of our high-class restaurants, the chefs of which will satisfy any gastronomic
            taste regardless of whether you prefer classic cuisine or exotic dishes. Our staff with
            We will be happy to offer you the best wines and drinks so that you can fully enjoy your stay. Each
            In the morning, our main restaurant serves a buffet breakfast with a wide and varied selection.</p>

          <p>You can relax in the SPA center, where you will be offered a wide range of various treatments for a complete
            relaxation and energy recovery. You can enjoy a massage, a Finnish sauna or a hammam.
            Our friendly and professional staff is always ready to take care of your well-being.</p>

          <p>For those who want to keep fit during their stay, our fitness center will provide
            We offer you first-class simulators and high-quality personal training. We have everything you need.
            to ensure that you stay in great shape, even while traveling.</p>

          <p>We are proud to offer our guests a high standard of hospitality in all our services. Our staff provides an individual approach to each guest, always ready to satisfy any of their wishes or needs. Thanks to our attentive service, your stay with us will be unforgettable.</p>
        `,
				'77': `
          <div class="mb-4">
            <p class="fw-bold m-0">What types of order are available</p>
            <span>You can order food and drinks via a QR code and the waiter will bring your order to the table or make an order yourself at the restaurant counter.</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">I want to order from several corners at once. Is it possible?</p>
            <span>Yes! You can make a combined order from any corners at the same time. Add the desired dishes to the cart and pay online.</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">I'm having trouble placing an order. Where should I contact?</p>
            <span>Please contact the delivery manager at +79308482525</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">How do I order alcoholic drinks?</p>
            <span>Here you can get acquainted with the alcohol map of the corners «Bar, wine and oysters», «Bebeer». Please note that alcoholic beverages are ordered at bars.</span>
          </div>
          <div class="mb-4">
            <p class="fw-bold m-0">Service fee</p>
            <span>A service fee of 10% of the order amount is automatically added.</span>
          </div>
        `,
				'82': `
          <p>The hotel in Volgograd &laquo;Yuzhny&raquo; is perfectly located in the business center of Volgograd and at the same time in the historical district.
            This unique combination of the city's history and the modern hotel building is reflected in the interior design.
          </p>

          <p>Within walking distance from the hotel there are: a beautiful park, Voroshilov shopping center,
            a shopping gallery, cinemas &laquo;Kinomax&raquo; and &laquo;Five Stars&raquo;.</p>

          <p>190 rooms, decorated with taste and love, gave the three-star hotel every reason to claim the status of a popular city hotel among the guests of Volgograd.</p>
        `,
				'empty': 'Information about the object is filling...'
			}
		},
		'detailpage': {
			'general': 'Learn more about the showcase <a href="mailto:info@stayday.com">info@stayday.com</a>',
			'placeError': 'Contact support - <a href="mailto:support@stayday.ru">support@stayday.ru</a>'
		}
	}
}
