<template>
  <div class="loading" :class="inline ? 'inline' : ''">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div>
</template>

<script>
export default {
  props: ['inline']
}
</script>
