import { getPlace, initParams } from '@/lib/utils'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import IdleVue from "idle-vue"
import vSelect from 'vue-select'
import Preloader from '@/components/preloader'
import { init as initI18n, enableMultilang, disableMultilang } from '@/i18n'
import reloadData from './i18n/mixins/reloadData'

const $ = require('jquery')

Vue.component('v-select', vSelect)
Vue.component('preloader', Preloader)

initI18n(Vue)

Vue.config.productionTip = false
let ipFormat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

let auth_token_env = process.env.VUE_APP_AUTH_TOKEN;

let env_server_api = process.env.VUE_APP_SERVER_API;
let EXTERNAL_CSS_ID = "externalCss";
let protocol = process.env.VUE_APP_SERVER_API_PROTOCOL ? process.env.VUE_APP_SERVER_API_PROTOCOL : "https://"

const VV = 8888 // 28.04.2023

if (!env_server_api) {
  env_server_api = 'https://dev-api.vitrina.stayday.com'
} else {
  if (env_server_api.match(ipFormat)) {
    env_server_api = 'http://' + process.env.VUE_APP_SERVER_API + ":5005"
  } else if (env_server_api === 'localhost') {
    env_server_api = 'http://' + process.env.VUE_APP_SERVER_API + ":5005"
  } else {
    env_server_api = protocol + process.env.VUE_APP_SERVER_API + ""
  }
}

// Vue.prototype.$server_api = env_server_api

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1000 * 60 * 15
})

export const EventBus = new Vue();

const app = new Vue({
  router,
  onIdle() {
    console.log('[IDLE]')
    this.user = null;
    if (this.$router.history.current.path !== '/home'){
      this.$router.push('/home');
    }

    this.$emit('user:logout');
    
    const place = getPlace()
    localStorage.removeItem(`user_${place}`);
  },

  onActive() {
    // console.log('active')
  },

  data: {
    host: env_server_api,
    auth_token_local: auth_token_env,
    ping: null,
    toggled: false,
    user: null,
    browsers: [],
    accounts: [],
    users: [],
    alerts: [],
    currency: "₽",
    hotel: {},
    view_adult_category: false,
  },
  mixins: [
    reloadData('getHotelInfo'),
  ],
  watch: {
    // observe hotel object update to load hotel styles
    hotel: function(newVal, oldVal) {
      // delay showing interface until hotel styles (if present) and all fonts are loaded
      let waitFor = [document.fonts.ready]
      if (newVal?.stylecss && (oldVal.stylecss !== newVal.stylecss)) {
        waitFor.push(this.loadStyleFile(newVal))
      }
      Promise.allSettled(waitFor).then(this.showApp)

      if (!this.$isServiceRoute && newVal.client_background && (oldVal.client_background !== newVal.client_background)) {
        let body = document.body;
        body.classList.add('hotel-bg');
        body.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${newVal.client_background})`;
      }
      
      // enable language selection if hotel has multilang enabled
      if (newVal.multilang) {
        enableMultilang()
      } else {
        disableMultilang()
      }
    }
  },
  methods: {
    // remove loader shown in index.html to uncover the App when needed styles and fonts are applied
    showApp: function() {
      document.getElementById('start-loader')?.remove()
      document.body.classList.remove('overflow-hidden')
    },
    alert: function (info) {
      info.date = new Date().getTime() + Math.floor(Math.random() * 1000)
      this.alerts.unshift(info);

      setTimeout(() => {
        this.alerts.shift();
      }, 5000);
    },
    alertClose: function (info) {
      this.alerts = this.alerts.filter(obj => obj.date !== info.date)
    },

    // возвращаем local storage когда он появляется, иначе ждем авторизации
    getTokenAsync: async function(){

      return new Promise((resolve) => {
        const place = getPlace()
        
        const token = localStorage.getItem(`token_${place}`)
        // if token present in local storage - resolve token
        if (!!token){
          resolve(token)
        }else{

          // else try auth with interval
          let interval = setInterval(async function(t){

            try {

              // auth with place or cookies info
              await t.auth();

              let token = localStorage.getItem(`token_${place}`)

              if (!!token) {
                // stop interval if token present, and resolve token
                clearInterval(interval)
                resolve(token)
              }
            }catch (e){
              console.error("token:err ", e.message)
            }
          },500, this)


        }
      });
    },

    // возвращаем local storage когда он появляется, иначе ждем авторизации
    getHotelAsync: async function(){

      return new Promise((resolve) => {

        const place = getPlace()
        const hotel = localStorage.getItem(`hotel_${place}`)
        // if token present in local storage - resolve token
        if (!!hotel){
          resolve(hotel)
        }else{

          // else try auth with interval
          let interval = setInterval(async function(t){

            try {

              // auth with place or cookies info
              await t.getHotelInfo();
              
              const hotel = localStorage.getItem(`hotel_${place}`)

              if (!!hotel) {
                // stop interval if token present, and resolve token
                clearInterval(interval)
                resolve(hotel)
              }
            }catch (e){
              console.error("token:err ", e.message)
            }
          },500, this)


        }
      });
    },

    auth: async function () {
      // var auth_token = localStorage.getItem('token')

      // console.log("AUTH TRY:")

      if (this.user) {
        const headers = await this.getHeaders()

        // получение данных по авторизованному клиенту
        $.get({
          url: `${this.$root.host}/api/users/${this.$root.user.id}`,
          headers,
          success: userResponse => {
            if (userResponse) {

              if (this.user.id !== userResponse.id){
                this.$root.$emit("object:change")
              }
              this.user = userResponse;
              const place = getPlace()
              localStorage.setItem(`user_${place}`, JSON.stringify(userResponse));
            }
          },
          error: err => {
            console.error('fetch user:err', err)
            this.user = null
            this.auth()
          }
        })

      } else {

        let place = getPlace()
        let userRequest = undefined

        if (place) {
          // TODO если есть place то авторизуемся по значению (ищем объект в апи по этому значению)
          userRequest = { place: place };
        } else {
          // TODO страница которая отображается если place не найден
          this.$router.push('/details?error=place');
          return
        }

        if (userRequest) {
          await $.post({
            url: `${this.$root.host}/api/auth`,
            data: userRequest,
            success: userResponse => {
              this.loading = false
              // console.log(" auth user 2 : ", userResponse)
              if (userResponse) {
                this.user = userResponse;
                this.$emit('user', userResponse);

                let userJson = JSON.stringify(userResponse);
                localStorage.setItem(`user_${place}`, userJson);
                localStorage.setItem(`token_${place}`, userResponse.token);
              }
            },
            error: err => {
              console.error('auth:err', err)
              this.$router.push('/details?error=place');
            }
          })
        }
      }

      if (this.user) {

        let hotelId = this.user.hotel_id
        const headers = await this.getHeaders()

        await $.get({
          url: `${this.$root.host}/api/hotels/${hotelId}`,
          headers,
          success: hotelInfo => {
            // console.log(" get hotel user : ", hotelInfo)
            if (hotelInfo) {

              if (this.hotel.id !== hotelInfo.id){
                this.$root.$emit("object:change")
              }

              this.hotel = hotelInfo
              const place = getPlace()
              localStorage.setItem(`hotel_${place}`, JSON.stringify(hotelInfo));
            }
          },
          error: err => {
            console.error('auth:err', err)
          }
        })
      }
    },

    getHotelInfo: async function () {

      // var auth_token = localStorage.getItem('token')

      if (this.user) {
        let hotelId = this.user.hotel_id
        const headers = await this.getHeaders()

        await $.get({
          url: `${this.$root.host}/api/hotels/${hotelId}`,
          headers,
          success: hotelInfo => {
            // console.log(" get hotel info : ", hotelInfo)
            if (hotelInfo) {
              this.hotel = hotelInfo
              const place = getPlace()
              localStorage.setItem(`hotel_${place}`, JSON.stringify(hotelInfo));
            }
          },
          error: err => {
            console.error('auth:err', err)
          }
        })
      }
    },


    loadStyleFile(hotel) {

      // console.log("OBJECT CHANGE EMIT CSS!!")

      let hotelStyleLink = undefined;

      if (hotel.stylecss){
          hotelStyleLink = `${hotel.stylecss}?v=${VV}`
      } else {
          hotelStyleLink = `https://storage.yandexcloud.net/vitrina/css/stylee.css?v=${VV}`;
      }

      let existCss = document.getElementById(EXTERNAL_CSS_ID)
      if (existCss){
        existCss.remove()
      }

      if (hotelStyleLink){
        let style = document.createElement('link');
        style.id = EXTERNAL_CSS_ID
        style.type = "text/css";
        style.rel = "stylesheet";
        let result = new Promise((resolve, reject) => {
          style.onload = resolve
          style.onerror = reject
        })
        style.href = hotelStyleLink;
        document.head.appendChild(style);
        return result;
      }
      return Promise.resolve();
    },

    async getHeaders() {
      let token  = await this.$root.getTokenAsync()
      return {
        Authorization: 'Bearer ' + token,
        'SD-Language': this.$i18next.language
      }
    },
  },
  
  beforeCreate: async function () {
    initParams( 3 )
  },

  created: async function () {
    const place = getPlace()

    // check user from local storage
    let user = localStorage.getItem(`user_${place}`);
    if (user) {
      this.user = JSON.parse(user);
    }

    // auth guest client in system
    await this.auth()
    await this.getHotelInfo()
  },

  mounted: async function () {
    console.log("[LOADED]")
    setInterval(async () => {

      try{
        await this.auth()
      }catch (e){
        console.error("auth:err ", e.message)
      }

    }, 20000);

    this.$root.$on("object:change", async () => {
      await this.getHotelInfo()
    })
  },

  render: h => h(App)
})

app.$mount('#app')
